$font: 'Poppins';
$open: 'Open Sans';

.font-old{
  font-family: $font;
}

.font-open{
  font-family: $open;
}

.italic{
  font-style: italic;
}

body{
  margin: 0;
  line-height: 1.2;
  font-weight: normal;
  color: $black;
  font-family: $font;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
    
}


@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-Black.ttf') format('ttf'),
  url('../assets/fonts/Poppins-Black.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 900;
}


@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-ExtraBold.ttf') format('ttf'),
  url('../assets/fonts/Poppins-ExtraBold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 800;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-Bold.ttf') format('ttf'),
  url('../assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-SemiBold.ttf') format('ttf'),
  url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-Medium.ttf') format('ttf'),
  url('../assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-Regular.ttf') format('ttf'),
  url('../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src:  url('../assets/fonts/Poppins-Light.ttf') format('ttf'),
  url('../assets/fonts/Poppins-Light.ttf') format('truetype');
  font-style: normal;
  font-stretch: normal;
  font-weight: 300;
}

html{
  scroll-behavior: smooth;
}

h1, .h1{
    font-weight: 700;
    margin-bottom: 26px;
    line-height: 1.2;
    font-size: 36px;
    line-height: 1.2;
    margin-bottom: 12px;
    font-family: $font;
  
    @include phone-m{
      font-size: 42px;
      line-height: 1.09;
      
      margin-bottom: 16px;
    }

  
    &.fs56{
  
      @include phone-m{
        font-size: 52px;
        line-height: 1.1;
      }
    }
  
    @extend %margins;
}

h2, .h2{
  font-weight: 700;
  margin-bottom: 16px;
  font-size: 26px;
  line-height: 1.24;
  font-family: $font;

  @include phone-m{
    font-size: 42px;
  }

  @extend %margins;
}

h3, .h3{
  font-weight: 700;
  font-size: 24px;
  line-height: 1.18;
  font-family: $font;

  @include phone-m{
    font-size: 34px;  
  }

  @extend %margins;
}

h4, .h4{
  font-family: $font;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
  
  margin-bottom: 16px;

  @include phone-m{
    font-size: 32px;
    line-height: 1.25;
    
  }

  @include tab-m{
    font-size: 32px;
  }

  @extend %margins;
}
  
h5, .h5{
    font-family: $font;
    font-weight: 600;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 15px;
  
    @include phone-m{
      font-size: 24px;

      &.fs28{
        font-size: 28px;
        line-height: 1.07;
      }
    }
  
    @extend %margins;
}
  
h6, .h6{
    line-height: 1.33;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 6px;
    // letter-spacing: -0.1px;

    @include phone-m{
      font-size: 18px;
    }
  
  
    @extend %margins;
}
  
p, .p{
    font-size: 14px;
    line-height: 1.45;
    color: $black;
    margin: 0;
  
    @include tabv-m{
      font-size: 14px;
    }
  
    &.sm{
      font-size: 11px;
      line-height: 1.37;
    }
  
    &.txt{
      font-weight: 600;
      color: $black;
    }
  
    &.md{
      font-size: 16px;
      line-height: 1.62;
    }

    &.lg{
      color: $black;

      @include phone-m{
        font-size: 16px;
      }
    }

    b{
      font-weight: 600;
    }
  
    @extend %margins;
}

ul{
  list-style: none;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 24px;

  li{
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.5;
    color: $gray;

    @include tabv-m{
      font-size: 16px;
    }
  }
}

.subtext{
  font-size: 14px;
  line-height: 1.14;
  color: $gray;
}

a{
  color: $black;
  text-decoration: none;
  outline: none !important;


  &:hover{
    color: $black;
    text-decoration: none;
  }
}
  
