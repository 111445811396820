

$white: white;
$black: #2B2E36;
$dark-1:#31384F;
$dark:#38363A;


$salmon: #F1DDCC;
$red: #FB3449;
$orange:#FF8818;
$orange1:#FFAF1A;
$purple:#7625FF;


$green:#13af28;
$aqua:#23FACF;
$pink:#F9F4FA;
$blue: #4D61AA;
$grey: #F0F2F1;


$text:#888888;
$gray:#C8CCD7;
$lite: #E6E8EE;


$nav-a: $black;
$nav-fs:13px;


$btn: linear-gradient(115.31deg, #FF8818 -28.13%, #FFCF18 171.11%);

:root{
  --white:white;
  --black:#1F303E;
  --text:#658499;
  --btn: #B3FCFF;
  --btn-color: #1F303E;
  --hbg: #83fbff;
  --lite:#F2F7F7;
}

$textColours: (
    "dark": $dark,
    "text": $text,
    "lite": $lite,
    "black": $black,
    "blue": $blue,
    "red": $red,
);

@each $textColour, $i in $textColours {
  .text-#{$textColour} {
        color: $i !important;
    }	
}

$bgColours: (
    "blue": $blue,
    "black": $black,
);

@each $bgColour, $i in $bgColours {
  .bg-#{$bgColour} {
        background-color: $i !important;
    }	
}

$border :  solid #D3D7E2;
$trans:  cubic-bezier(0, 0, 0.2, 1);



@mixin blank($color:$dark){
    background: none;
    border: none;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    color: $color;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    outline: none;
    box-sizing: none;
}

.blank-btn {
@include blank;
}

@mixin disable($color:$dark){
    background: none;
    border: none;
    padding: 0;
    font-size: 12px;
    line-height: 20px;
    color: $color;
    font-weight: 600;
    display: inline-block;
    cursor: pointer;
    outline: none;
    box-sizing: none;
    opacity: 0.5;
}

.disable-btn {
@include disable;
}

.blank-blue{
@include blank($color:$blue);
}

@mixin null-btn($mb:0px){
background: none;
background-color: transparent;
border: none;
padding: 0;
display: inline-block;
margin-bottom: $mb;
cursor: pointer;
transition: all 180ms $trans;

&:hover{
    transform: scale(1.12);
}


&.colored{
    filter: grayscale(0);
}
}

.null-btn{
@include null-btn;
}

  

$margins: 0,4,6,8,10,12,16,18,20,24,28,32,34,36,38,40;

%margins{
	@each $margin in $margins {
		&.m#{$margin} {
			margin-bottom: #{$margin}px !important;
		}	
    margin-top: 0px;
	}
}

$spaces: (0,4,6,8,10,12,16,18,20,24,28,32,36,38,40);
$sides:(top, bottom, left, right);

@each $space in $spaces{
  @each $side in $sides{
    .m#{str-slice($side, 0, 1)}#{$space}{
      margin-#{$side}: #{$space}px !important ;
    }

    .p#{str-slice($side, 0, 1)}#{$space}{
      padding-#{$side}: #{$space}px !important ;
    }
  }
}


$fw: 100,200,300,400,500,600,700,800,900;

@each $i in $fw {
  .fw#{$i} {
    font-weight: #{$i} !important;
  }	
}

$fs: 10,12,14,16,18,20,22,24,26;

@each $i in $fs {
  .fs#{$i} {
    font-size: #{$i}px !important;
  }	
}

$va: 1, 2, 3, 4 , 0, -1, -2, -3, -4;

@each $i in $va {
  .va#{$i} {
    vertical-align: #{$i}px !important;
  }	
}



.btn-grp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  
    @include phone{
      flex-direction: column;
    }
  
    &.mt60{
      margin-top: 60px;
    }
  
    a,button{
      margin-top: 10px;
      
      @include phone{
        width: auto;
      }
  
      @include phone-m{
        margin-left: 24px;
        margin-top: 0;
      }
      
      &:first-child{
        margin-left: 0;
  
        @include phone{
          margin-top: 0;
        }
      }
    }
    
    &.center{
      justify-content: center;
    }
  
  }

  

@mixin ab{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

@mixin img($ob: contain){
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: $ob;
}

@mixin str{

  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  
}

.str{
  @include str;
}

@mixin str-i{
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 4;
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  margin: 0;
}

@mixin no-scrollbar{
/* width */
&::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

/* Track */
&::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
&::-webkit-scrollbar-thumb {
    background: transparent;
}

/* Handle on hover */
&::-webkit-scrollbar-thumb:hover {
    background: transparent;
}
}




.d-block{
    display: block;
  }
  
  .text-center{
    text-align: center;
  }
  
  .text-left{
    text-align: left;
  }
  
  .d-flex{
    display: flex;
  }
  
  .ml-auto{
    margin-left: auto;
  }
  
  .row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
  
    >*{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  
  .form-row{
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
    margin-right: -8px;
  
    >*{
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  



$icons: (
    "close": 40px, "eye-closed": 24px, "eye": 24px, "etherium": 14px, "hourglass": 22px, "clock": 22px, "popper": 22px, "green-tick": 12px, "youtube": 24px, "discord": 24px, "instagram": 24px, "mail": 30px, "twitter": 32px, "telegram": 32px, "discord-lg": 32px, "setting": 24px, "bell": 24px, "gear": 24px, "wallet": 24px, "users": 24px, "bookmark": 24px, "page": 24px, "logout": 24px, "check-round": 24px, "connected": 16px, "walletconnect": 32px, "rainbow": 32px, "coinbase": 32px, "metamask": 32px, "tick-lg": 40px, "heart": 14px, "bookmark-white": 18px,  "filter": 24px, "sort-up": 14px,"sort-down":13px, "camera": 50px, "delete": 24px, "status": 24px, "timezone": 24px,  "joined": 24px, "mag": 24px, "email": 20px, "user": 20px, "info": 24px, "block": 24px, "folder": 24px, "cross":20px, "remove":16px, "featured":24px,
    
  );
  
  @each $icon, $i in $icons {
    .#{$icon}-icon {
      width: $i;
      height: $i;
      display: inline-block;
      vertical-align: middle;
      background: url(../assets/images/icons/#{$icon}-icon.svg) no-repeat center;
    }
  }

.discord-icon{
  &.lg{
      width: 32px;
      height: 32px;
      background-size: contain;
    
  }
}

.etherium-icon{

  &.white{
    filter: invert(1) brightness(12);
  }

  &.grey{
    filter: grayscale(1)  brightness(2);
  }

  &.lg{
    width: 21px;
    height: 21px;
    background-size: contain;
  }

  &.xl{
    width: 24px;
    height: 24px;
    background-size: contain;
  }

  &.big{
    height: 28px;
    width: 28px;
    background-size: contain;
  }
}